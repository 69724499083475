/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import PartnerData from '../containers/PartnerData'
import { useData } from '../context'

import { useAnalyticsEvents } from '../hooks/useAnalyticsEvent'
import useHandleConfirmationButton from '../hooks/useConfirmationButtonHandle'

const PartnerDetailsPage = ({ history, redirect, t }) => {
  const { data, setData } = useData()
  const [occupations, setOccupations] = useState([])
  const {
    handlePreviousButtonClick,
    handleSubmitForm,
  } = useHandleConfirmationButton()

  const { sendEvent } = useAnalyticsEvents()

  const trackPartnerDataPageView = useCallback(() => {
    sendEvent({
      cta_reference: 'dados-do-representante',
      deduplicate: false,
      description:
        'Evento disparado quando o usuário acessa a tela "dados-do-representante"',
      entity: 'page',
      label: '',
      name: 'page view pagarme',
      section_reference: 'dados-do-representante',
    })
  }, [sendEvent])

  const handleSubmit = newRegisterData => {
    newRegisterData.partnerName = `${newRegisterData.partnerFirstName} ${newRegisterData.partnerLastName}`

    delete newRegisterData.partnerFirstName
    delete newRegisterData.partnerLastName

    window.dataLayer.push({
      birth_date: newRegisterData.birthDate,
      phone: newRegisterData.phone,
    })

    setData({
      ...data,
      ...newRegisterData,
    })

    redirect('/endereco-do-representante')
  }

  const isProd = process.env.REACT_APP_API_ENVIRONMENT === 'production'

  useEffect(() => {
    trackPartnerDataPageView()
    ;(async () => {
      const { data: ocuppationData } = await axios.get(
        isProd
          ? 'https://api.mundipagg.com/bigbang/v1/occupation'
          : 'https://stgapi.mundipagg.com/bigbang/v1/occupation',
      )

      const ocuppationsTitles = ocuppationData.map(occupation => ({
        label: occupation.title,
        value: occupation.title,
      }))

      setOccupations(ocuppationsTitles)
    })()
  }, [isProd, trackPartnerDataPageView])

  const handlePreviousClick = () => {
    handlePreviousButtonClick()
    history.goBack()
  }

  const handleSubmitClick = newRegisterData => {
    handleSubmitForm()
    handleSubmit(newRegisterData)
  }

  return (
    <PartnerData
      occupations={occupations}
      onPreviousButton={handlePreviousClick}
      onSubmit={handleSubmitClick}
      registerData={data}
      t={t}
    />
  )
}

PartnerDetailsPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default PartnerDetailsPage
