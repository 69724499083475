/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Form } from '../components/Form'
import CompanyAddress from '../containers/CompanyAddress'
import { useData } from '../context'
import { getCep } from '../vendor/helpers/getCep'

import { useAnalyticsEvents } from '../hooks/useAnalyticsEvent'
import useHandleConfirmationButton from '../hooks/useConfirmationButtonHandle'

const CompanyAddressPage = ({ history, redirect, t }) => {
  const [disableInput, setDisableInput] = useState(false)
  const { data, setData } = useData()
  const {
    handlePreviousButtonClick,
    handleSubmitForm,
  } = useHandleConfirmationButton()
  const { sendEvent } = useAnalyticsEvents()

  const trackCompanyAddressPageView = useCallback(() => {
    sendEvent({
      cta_reference: 'endereco-da-empresa',
      deduplicate: false,
      description:
        'Evento disparado quando o usuário acessa a tela "endereco-da-empresa"',
      entity: 'page',
      label: '',
      name: 'page view pagarme',
      section_reference: 'endereco-da-empresa',
    })
  }, [sendEvent])

  const handleCepRequest = async (cep, form) => {
    setDisableInput(true)
    const cepData = await getCep(cep)

    form.change('companyCep', cep)
    form.change('companyCity', cepData.city)
    form.change('companyNeighborhood', cepData.neighborhood)
    form.change('companyState', cepData.state)
    form.change('companyStreet', cepData.street)

    setDisableInput(false)
  }

  useEffect(() => trackCompanyAddressPageView(), [trackCompanyAddressPageView])

  const handleSubmit = newRegisterData => {
    setData({
      ...data,
      ...newRegisterData,
    })

    redirect('/dados-bancarios')
  }

  const handlePreviousClick = () => {
    handlePreviousButtonClick()
    history.goBack()
  }

  const handleSubmitClick = newRegisterData => {
    handleSubmitForm()
    handleSubmit(newRegisterData)
  }

  return (
    <Form
      data={data}
      onPreviousButton={handlePreviousClick}
      onSubmit={handleSubmitClick}
      t={t}
    >
      <CompanyAddress
        disableInput={disableInput}
        getCep={handleCepRequest}
        t={t}
      />
    </Form>
  )
}

CompanyAddressPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default CompanyAddressPage
