/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import CompanyData from '../containers/CompanyData'
import { useData } from '../context'
import getPagarmePlatforms from '../vendor/helpers/getPagarmePlatforms'

import { useAnalyticsEvents } from '../hooks/useAnalyticsEvent'
import useHandleConfirmationButton from '../hooks/useConfirmationButtonHandle'

const CompanyDetailsPage = ({ history, redirect, t }) => {
  const { data, setData } = useData()
  const [platforms, setPlatforms] = useState([])

  const {
    handlePreviousButtonClick,
    handleSubmitForm,
  } = useHandleConfirmationButton()
  const { sendEvent } = useAnalyticsEvents()

  const trackCompanyDataPageView = useCallback(() => {
    sendEvent({
      cta_reference: 'dados-da-empresa',
      deduplicate: false,
      description:
        'Evento disparado quando o usuário acessa a tela "dados-da-empresa"',
      entity: 'page',
      label: '',
      name: 'page view pagarme',
      section_reference: 'dados-da-empresa',
    })
  }, [sendEvent])

  const handleSubmit = newRegisterData => {
    setData({
      ...data,
      ...newRegisterData,
    })

    redirect('/dados-do-representante')
  }

  useEffect(() => {
    trackCompanyDataPageView()
    ;(async () => {
      const filteredActivePlataforms = await getPagarmePlatforms()

      setPlatforms(filteredActivePlataforms)
    })()
  }, [setPlatforms, trackCompanyDataPageView])

  const handlePreviousClick = () => {
    handlePreviousButtonClick()
    history.goBack()
  }

  const handleSubmitClick = newRegisterData => {
    handleSubmitForm()
    handleSubmit(newRegisterData)
  }
  return (
    <CompanyData
      onPreviousButton={handlePreviousClick}
      onSubmit={handleSubmitClick}
      platforms={platforms}
      registerData={data}
      t={t}
    />
  )
}

CompanyDetailsPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default CompanyDetailsPage
