/* eslint-disable camelcase */
/* eslint-disable sort-keys */
import * as amplitude from '@amplitude/analytics-browser'
import { useCallback } from 'react'
import { getCurrentPath } from '../utils/url'
import {
  getCategory,
  getUserDeviceType,
  getUserAgent,
  getUserSource,
  getGAClientId,
  getUserSyncId,
  getAllUserExperimentsAndVariants,
  checkDuplicated,
  getInsertId,
  getGASessionId,
} from '../utils/analytics'

export const sendGaEvent = (action, params = {}) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, params)
  }
}

export const useAnalyticsEvents = () => {
  const currentPage = getCurrentPath() || 'homepage'
  const environment = process.env.NODE_ENV || 'development'

  const sendEvent = useCallback(
    ({
      cta_reference,
      deduplicate = false,
      description,
      entity = 'page',
      label,
      metadata,
      name,
      section_reference,
    }) => {
      const insertID = getInsertId()

      if (deduplicate) {
        const key = `${name ||
          entity}${cta_reference}${section_reference}${label}`
        if (checkDuplicated(insertID + getCategory(), key)) return
      }

      const eventData = {
        category: getCategory(),
        cross_lead_id: getGASessionId(),
        experiments: JSON.stringify(getAllUserExperimentsAndVariants()),
        ga_client_id: getGAClientId(),
        source: getUserSource(),
        user_agent: getUserAgent(),
        user_device: getUserDeviceType(),
        user_sync_id: getUserSyncId(),
      }

      const eventEntity = entity === 'page' ? currentPage : entity

      if (!eventEntity) return

      const treatedLabel = label
        .replace('{currentPage}', currentPage)
        .toLowerCase()

      sendGaEvent(name ?? eventEntity, {
        description,
        name,
        cta_reference,
        section_reference,
        ...eventData,
      })

      amplitude.track({
        event_properties: {
          description,
          entity: eventEntity,
          feature: 'view',
          label: treatedLabel,
          name,
          section_reference,
          cta_reference,
          metadata: {
            env: environment,
            ...metadata,
          },
          ...eventData,
        },
        event_type: name || eventEntity,
      })
    },
    [currentPage, environment],
  )

  return { sendEvent }
}
