import React, { Component } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Confirmation from '../containers/Confirmation'
import { activate } from '../api/company'
import reactGTag from '../vendor/reactGTag'
import buildCompanyShouldResetPassword from '../utils/buildCompanyShouldResetPassword'
import { pagarme1SellerId } from '../environment'

const enhanced = compose(translate(), withRouter)

/* eslint-disable no-undef */
const makeRedirect = (shouldResetPassword, passwordResetToken, t) => {
  if (shouldResetPassword) {
    return {
      text: t('pages.confirmation.go_to_create_password'),
      value: () =>
        window.location.replace(
          `https://beta.dashboard.pagar.me/#/account/password/reset/${passwordResetToken}`,
        ),
    }
  }

  return {
    text: t('pages.confirmation.go_to_guide'),
    value: () =>
      window.location.replace(
        'https://docs.pagar.me/page/bem-vindo-ao-pagarme',
      ),
  }
}
/* eslint-enable */

class ConfirmationPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      companyActivate: true,
      isLoading: true,
    }
  }

  async componentDidMount() {
    await this.loadState()

    reactGTag.event({ action: 'optimize.credenciamento' })
  }

  async loadState() {
    const { location } = this.props
    const { search } = location
    const { token } = queryString.parse(search)

    try {
      const response = await activate(token)
      const {
        password_reset_token: passwordResetToken,
        seller_id: sellerId,
        type,
      } = await response.json()
      const { ok, status } = response
      const companyShouldResetPassword = buildCompanyShouldResetPassword(
        pagarme1SellerId,
      )
      this.setState({
        companyActivate: ok && status === 200,
        passwordResetToken,
        shouldResetPassword: companyShouldResetPassword({ sellerId, type }),
      })
    } catch (error) {
      this.setState({ companyActivate: false })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render() {
    const { t } = this.props
    const {
      companyActivate,
      isLoading,
      passwordResetToken,
      shouldResetPassword,
    } = this.state

    return (
      <Confirmation
        hasActivated={companyActivate}
        isLoading={isLoading}
        redirect={makeRedirect(shouldResetPassword, passwordResetToken, t)}
        shouldResetPassword={shouldResetPassword}
        t={t}
      />
    )
  }
}

ConfirmationPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
}

export default enhanced(ConfirmationPage)
