/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { FullStoryAPI } from 'react-fullstory'
import CreateAccount from '../containers/CreateAccount'
import { useData } from '../context'
import { useAnalyticsEvents } from '../hooks/useAnalyticsEvent'

import useHandleConfirmationButton from '../hooks/useConfirmationButtonHandle'

const WelcomePage = ({ redirect, t }) => {
  const { data, gaParams, setData, utmParams } = useData()
  const { handleSubmitForm } = useHandleConfirmationButton()
  const { sendEvent } = useAnalyticsEvents()

  const trackWelcomePageView = useCallback(() => {
    sendEvent({
      cta_reference: 'boas-vindas-ao-pagarme',
      deduplicate: false,
      description:
        'Evento disparado quando o usuário acessa a tela "boas-vindas-ao-pagarme"',
      entity: 'page',
      label: '',
      name: 'page view pagarme',
      section_reference: 'boas-vindas-ao-pagarme',
    })
  }, [sendEvent])

  useEffect(() => {
    trackWelcomePageView()
    FullStoryAPI('setUserVars', { token_str: data.companyTemplateToken })
  }, [data.companyTemplateToken, trackWelcomePageView])

  const handleSubmit = newRegisterData => {
    FullStoryAPI('setUserVars', {
      email: newRegisterData.email,
    })

    const { isPartner } = newRegisterData

    newRegisterData.adminName = `${newRegisterData.first_name} ${newRegisterData.last_name}`

    window.dataLayer.push({
      email: newRegisterData.email,
      first_name: newRegisterData.first_name,
      last_name: newRegisterData.last_name,
    })

    setData({
      ...data,
      ...newRegisterData,
      ...utmParams,
      ...gaParams,
      partnerName: isPartner === 'Sim' ? newRegisterData.adminName : undefined,
    })
    handleSubmitForm()
    redirect('/dados-da-empresa')
  }

  return <CreateAccount onSubmit={handleSubmit} registerData={data} t={t} />
}

WelcomePage.propTypes = {
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default WelcomePage
