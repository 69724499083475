export const removeInitialAndFinalSlashs = path => {
  if (typeof path !== 'string') {
    throw new TypeError('The provided path must be a string.')
  }

  return path.trim().replace(/(^\/+)|(\/+$)/g, '')
}

export const getCurrentPath = () => {
  if (typeof window === 'undefined') return ''

  return removeInitialAndFinalSlashs(window?.location?.pathname)
}

export const getQueryParams = href => {
  if (typeof window === 'undefined' || !href) return ''

  const queryParams = window.location?.search || ''
  const queryParamsSeparator = href?.includes('?') ? '&' : '?'

  return queryParams
    ? `${queryParamsSeparator}${queryParams?.substring(1)}`
    : ''
}
