import { useCallback } from 'react'
import { sendGaEvent } from './useAnalyticsEvent'

const useHandleConfirmationButton = () => {
  const handleClickConfirmationButton = useCallback(
    (ctaReference, actionType) => {
      sendGaEvent({
        cta_reference: ctaReference,
        deduplicate: false,
        description: `Evento disparado quando o usuário ${actionType} o formulário`,
        entity: 'page',
        label: actionType === 'continuar' ? 'Continuar' : 'Voltar',
        name: `cta pagarme ${actionType}`,
        section_reference: 'Comece a Vender Hoje com o Pagar.me',
      })
    },
    [],
  )

  const handlePreviousButtonClick = useCallback(() => {
    handleClickConfirmationButton('voltar-formulario', 'volta')
  }, [handleClickConfirmationButton])

  const handleSubmitForm = useCallback(() => {
    handleClickConfirmationButton('enviar-formulario', 'continuar')
  }, [handleClickConfirmationButton])

  return {
    handlePreviousButtonClick,
    handleSubmitForm,
  }
}

export default useHandleConfirmationButton
