/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
export function getStoredVariant(experimentId) {
  return globalThis.localStorage.getItem(
    `amplitude-experiment-variant-${experimentId}`,
  )
}

export function storeUserVariant(variant, experimentId) {
  globalThis.localStorage.setItem(
    `amplitude-experiment-variant-${experimentId}`,
    variant,
  )
}

export function ensureGetUserId() {
  let userId = globalThis.localStorage.getItem('amplitude-user-id')
  if (userId !== null) return userId

  userId = window.crypto
    .getRandomValues(new Uint32Array(5))
    .reduce((all, current) => all.concat(current.toString(16)), '')

  globalThis.localStorage.setItem('amplitude-user-id', userId)

  return userId
}

export function getCategory() {
  return globalThis.window.location.host + globalThis.window.location.pathname
}

export function getGASessionId() {
  try {
    if (!window) return undefined
    const cookie = {}
    document.cookie.split(';').forEach(el => {
      const splitCookie = el.split('=')
      const key = splitCookie[0].trim()
      const value = splitCookie[1]
      cookie[key] = value
    })
    return cookie._ga_D3WKQ4C3N8.substring(6).split('.')[0]
  } catch (error) {
    return 'not_accept_cookies_yet'
  }
}

export function getUserDeviceType() {
  if (!globalThis.window) return 'Server'
  const width = globalThis.window.innerWidth
  if (width < 768) {
    return 'Mobile'
  }
  if (width >= 768 && width < 1024) {
    return 'Tablet'
  }
  return 'Desktop'
}

export function getUserAgent() {
  return globalThis.window?.navigator?.userAgent
}

export function getUserSource() {
  return sessionStorage?.getItem('amplitude-source') || 'entrance'
}

export function getGAClientId() {
  try {
    const cookie = {}
    document.cookie.split(';').forEach(el => {
      const splitCookie = el.split('=')
      const key = splitCookie[0].trim()
      const value = splitCookie[1]
      cookie[key] = value
    })
    return cookie._ga.substring(6)
  } catch (error) {
    return 'not_accept_cookies_yet'
  }
}

export function getUserSyncId() {
  return sessionStorage?.getItem('user-sync-id') || ''
}

export function getAllUserExperimentsAndVariants() {
  const experiments = Object.entries(localStorage)
    .filter(([key]) => key.startsWith('amplitude-experiment-variant-'))
    .map(([experiment, variant]) => ({
      experiment: experiment.replace('amplitude-experiment-variant-', ''),
      variant,
    }))

  return experiments
}

function getUnixTimestampsFromStartOfMinute() {
  const now = new Date()
  now.setSeconds(0)
  now.setMilliseconds(0)
  return now.getTime()
}

export function getInsertId() {
  const unixTimestamp = getUnixTimestampsFromStartOfMinute()
  const userId = ensureGetUserId()
  return `${userId}-${unixTimestamp}`
}

export function checkDuplicated(insertId, eventName) {
  if (sessionStorage.getItem(insertId + eventName)) {
    return true
  }

  sessionStorage.setItem(insertId + eventName, 'tracked')

  return false
}
