import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Form, TextInput } from '../../components/Form'
import CustomDropDown from '../../components/Form/CustomDropdown/CustomDropDown'
import mainPlatforms from '../../models/main_platforms.json'
import formatNumberToBRLCurrency from '../../vendor/helpers/formatNumberToBRLCurrency'
import * as S from './styles'
import { isCnpj, isHttps, isPhone, isRequired, isUrl } from './validations'

const CompanyData = ({
  onPreviousButton,
  onSubmit,
  platforms,
  registerData,
  t,
}) => {
  const [annualBillingValue, setAnnualBillingValue] = useState('')

  const handleChange = event => {
    const formattedValue = formatNumberToBRLCurrency(event.target.value)
    setAnnualBillingValue(formattedValue)
  }

  return (
    <Form
      data={registerData}
      onPreviousButton={onPreviousButton}
      onSubmit={onSubmit}
      t={t}
    >
      <S.Row>
        <TextInput
          mask="11.111.111/1111-11"
          name="cnpj"
          placeholder={t('pages.company_data.cnpj_placeholder')}
          title={t('pages.company_data.cnpj')}
          type="text"
          validations={[isRequired(t), isCnpj(t)]}
        />
        <TextInput
          mask="(11) 11111-1111"
          name="phoneNumber"
          placeholder={t('pages.company_data.phone_number_placeholder')}
          title={t('pages.company_data.phone_number')}
          type="text"
          validations={[isRequired(t), isPhone(t)]}
        />

        <TextInput
          name="annualBilling"
          onChange={handleChange}
          parse={number => formatNumberToBRLCurrency(number)}
          placeholder={t('pages.company_data.annual_billing_placeholder')}
          title={t('pages.company_data.annual_billing')}
          type="text"
          validations={[isRequired(t)]}
          value={annualBillingValue}
        />
      </S.Row>
      <S.Row>
        <CustomDropDown
          mainPlatforms={mainPlatforms}
          name="webPlatform"
          options={platforms}
          placeholder={t('pages.company_data.select_platform_placeholder')}
          title={t('pages.company_data.select_platform')}
          validations={[isRequired(t)]}
        />
        <TextInput
          name="website"
          placeholder={t('pages.company_data.web_site_placeholder')}
          title={t('pages.company_data.web_site')}
          type="text"
          validations={[isRequired(t), isUrl(t), isHttps(t)]}
        />
      </S.Row>

      <TextInput
        name="tradeName"
        placeholder={t('pages.company_data.trade_name_placeholder')}
        title={t('pages.company_data.trade_name')}
        type="text"
        validations={[isRequired(t)]}
      />
      <TextInput
        name="legalName"
        placeholder={t('pages.company_data.legal_name_placeholder')}
        title={t('pages.company_data.legal_name')}
        type="text"
        validations={[isRequired(t)]}
      />
    </Form>
  )
}

CompanyData.propTypes = {
  onPreviousButton: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  platforms: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  registerData: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
}

CompanyData.defaultProps = {
  registerData: {},
}

export default CompanyData
