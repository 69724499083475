/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Form } from '../components/Form'
import PartnerAddress from '../containers/PartnerAddress'
import { useData } from '../context'
import { getCep } from '../vendor/helpers/getCep'

import { useAnalyticsEvents } from '../hooks/useAnalyticsEvent'

import useHandleConfirmationButton from '../hooks/useConfirmationButtonHandle'

const PartnerAddressPage = ({ history, redirect, t }) => {
  const { data, setData } = useData()
  const [disableInput, setDisableInput] = useState(false)
  const {
    handlePreviousButtonClick,
    handleSubmitForm,
  } = useHandleConfirmationButton()

  const { sendEvent } = useAnalyticsEvents()

  const trackPartnerAddressPageView = useCallback(() => {
    sendEvent({
      cta_reference: 'endereco-do-representante',
      deduplicate: false,
      description:
        'Evento disparado quando o usuário acessa a tela "endereco-do-representante"',
      entity: 'page',
      label: '',
      name: 'page view pagarme',
      section_reference: 'endereco-do-representante',
    })
  }, [sendEvent])

  const handleCepRequest = async (cep, form) => {
    setDisableInput(true)
    const cepData = await getCep(cep)

    form.change('partnerCep', cep)
    form.change('partnerCity', cepData.city)
    form.change('partnerNeighborhood', cepData.neighborhood)
    form.change('partnerState', cepData.state)
    form.change('partnerStreet', cepData.street)

    setDisableInput(false)
  }

  useEffect(() => trackPartnerAddressPageView(), [trackPartnerAddressPageView])

  const handleSubmit = newRegisterData => {
    window.dataLayer.push({
      city: newRegisterData.partnerCity,
      state: newRegisterData.partnerState,
      zipcode: newRegisterData.partnerCep,
    })

    setData({
      ...data,
      ...newRegisterData,
    })

    redirect('/endereco-da-empresa')
  }

  const handlePreviousClick = () => {
    handlePreviousButtonClick()
    history.goBack()
  }

  const handleSubmitClick = newRegisterData => {
    handleSubmitForm()
    handleSubmit(newRegisterData)
  }

  return (
    <Form
      data={data}
      onPreviousButton={handlePreviousClick}
      onSubmit={handleSubmitClick}
      t={t}
    >
      <PartnerAddress
        disableInput={disableInput}
        getCep={handleCepRequest}
        t={t}
      />
    </Form>
  )
}

PartnerAddressPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default PartnerAddressPage
